import React, { FC, useEffect, useState, useContext } from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogContentText, withStyles, WithStyles, Divider, DialogTitle, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Term from './term';
import { StyleRules } from '@material-ui/styles';
import { Icon } from '@mdi/react';
import { mdiAlertBoxOutline } from '@mdi/js';
import { AlertsContext } from 'contexts/alerts.context';
import { BLUE } from 'containers/shared/colors';

const styles = (): StyleRules => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: 5,
    },
    title: {
        width: '90%',
        color: 'white',
    },
    iconAlert: {
       marginLeft: 10,
       marginBottom: -8,
    }
});

type Props = WithStyles<typeof styles>;

const AlertDialog: FC<Props> = ({ classes }) => {
    const [alerts, alertsDispatch] = useContext(AlertsContext);
    const [color, setColor] = useState('red');

    const formLabelsTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                h6: {
                    fontSize: 32,
                    color: '#004f84',
                    fontFamily: 'Roboto',
                    fontWeight: 'lighter',
                }
            },
            MuiButton:{
                textPrimary:{
                    color: BLUE,
                }
            }
        }
    })

    useEffect(() => {
        if (alerts.alert) {
            switch (alerts.alert.variant) {
                case 'success':
                    setColor('green');
                    break;
                case 'warning':
                    setColor('#004f84');
                    break;
                case 'info':
                    setColor('blue');
                    break;
                default:
                    setColor('red');
                    break;
            }
        }
    }, [alerts.alert]);

    const handleClose = () => {
        alertsDispatch({ type: 'hide-alert' });
    };

    return alerts.alert
        ? (
            <MuiThemeProvider theme={formLabelsTheme}>
                <Dialog 
                    maxWidth={alerts.alert.size ??'sm'}
                    open={alerts.alert.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <Term component="Global" text="Sede Electrónica" />
                            <Icon path={mdiAlertBoxOutline}
                                size={"42px"}
                                color={color}
                                className={classes.iconAlert}
                            />
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        {
                            alerts.alert.isHtml === true && typeof alerts.alert.message === 'string'
                                ? <div dangerouslySetInnerHTML={{ __html: alerts.alert.message }} />
                                : <DialogContentText id="alert-dialog-description">
                                    {alerts.alert.message}
                                </DialogContentText>
                        }
                    </DialogContent>
                    {!alerts.alert.hideButtonAction && 
                        <>
                        <Divider />
                        <DialogActions style={{color:BLUE}}>
                                {alerts.alert.hasCustomAction && 
                                    <Button
                                        onClick={alerts.alert.handleCustomAction}
                                        color="primary"
                                        
                                    >
                                        <Term component="Global" text={alerts.alert.actionTemplateName ?? ''} /> 
                                    </Button>
                                }
                                <Button
                                    onClick={handleClose}
                                    color="primary"
                                    autoFocus= {!alerts.alert.hasCustomAction}
                                >
                                <Term component="Global" text={alerts.alert.defaultCloseActionTemplateName ?? "Cerrar"} />
                                </Button>
                                
                            
                        </DialogActions>
                        </>
                    }
                </Dialog>
            </MuiThemeProvider>
        ) : null
};

export default withStyles(styles)(AlertDialog);