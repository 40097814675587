// LIBRARY IMPORTS
import React, { FC, useContext, useMemo, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Dialog, Typography, Button, CircularProgress, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme, makeStyles } from "@material-ui/core";
import { mdiCheck, mdiWindowClose, mdiEmailSendOutline, mdiChevronRight, mdiChevronDown, mdiClose, mdiCalendarMonth } from '@mdi/js';
import { Icon } from '@mdi/react'
import moment from 'moment';

// LOCAL IMPORTS
import { IDatosDeudaPagoCarta, IDatosPlazos, IRecibo, IResultPagosAcuenta } from 'gateways/recibo.interfaces';
import { dateFormat } from 'utils/dates';
import { RecibosGateway } from 'gateways/recibo.gateway';
import { PaymentGateway } from 'gateways/payment.gateway';
import { LiteralsContext } from 'containers/shared/literals';
import { download } from 'utils/download';
import { translate } from 'utils/i18n';
import { IFilterOption } from 'utils/interfaces';
import { FilterDataContext } from './filter.data.context';
import { AlertsContext } from 'contexts/alerts.context';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import TextNumber from 'components/text-number';
import IoC from 'contexts/ioc.context';
import Term from 'components/term';
import ConceptoIcon from 'components/concepto.icon';

// STYLES
import styles from '../shared/tributo.detail.styles';
import { ScreenType } from './filters.form';

moment.locale('es-es');

const useStyles = makeStyles(styles);

export interface IProps {
    selectable: boolean;
    open: boolean;
    recibo: IRecibo;
    selected: boolean;
    mensajeAclaratorio: string;
    puedeIdentificar: boolean;
    isFromConsultasExternas: boolean;
    bodyAvisoPagoCartaPalma: string;
    screen?: ScreenType;
    onClose: () => void;
    onSelect: (recibo: IRecibo) => void;
    onUnselect: (recibo: IRecibo) => void;
}

const ReciboDetail: FC<IProps> = (props) => {
    const { selectable, open, recibo, selected, puedeIdentificar, isFromConsultasExternas, bodyAvisoPagoCartaPalma, screen, onClose, onSelect, onUnselect } = props;

    // HOOKS
    const filterData = useContext(FilterDataContext);
    const terms = useContext(LiteralsContext);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [, alertsDispatch] = useContext(AlertsContext);

    // GATEWAYS
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);
    const paymentGateway = ioc.get(PaymentGateway) as PaymentGateway;
    const contenidosGateway = ioc.get(ContenidosGateway) as ContenidosGateway;

    // STATES
    const municipio = filterData && filterData.organismos ? filterData.organismos.find((x: IFilterOption<string>) => x.value === recibo.organismo.codigo) : null;
    const pendiente = recibo.desglose.pendiente > 0;

    // BOOLEAN STATES
    const [loading, setLoading] = useState(false);
    const [openDatosPC, setOpenDatosPC,] = useState(false)
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [excedenteCobro, setExcedenteCobro] = useState(false);
    const [loadingPrintJustificantePagoCuenta, setLoadingPrintJustificantePagoCuenta] = useState(false);

    // LOCAL STATES
    const [lineasAux, setLineasAux] = useState('');
    const [datosPc, setDatosPc] = useState<IDatosDeudaPagoCarta>()

    // PAGO A CUENTA STATES
    const [expand, setExpand] = useState(false)
    const [pagosACuentaList, setPagosACuentaList] = useState<IResultPagosAcuenta[]>([])
    const [importePagadoAPlazos, setImportePagadoAPlazos] = useState(0);

    const esPagoACuenta = useMemo(() => recibo.estadoEtiqueta === 'pago_a_cuenta_domiciliado', [recibo]);



    // ----------------------------- PAGO A CUENTA -----------------------------

    const getListaPagos = async (idDomiRec: number) => {
        const result = await gateway.getListadoPagosACuenta(idDomiRec);
        setPagosACuentaList(result);
        setImportePagadoAPlazos(result.reduce((acc, pago) => acc + pago.importe, 0));
    };

    const toggleExpand = () => { setExpand(prev => !prev) };

    const handleModifPagoACuenta = () => {
        history.push('/cartero-virtual/deuda-pendiente/pago-a-cuenta-domiciliado', { recibo });
    };

    const handlePrintJustificantePagoCuentaReciboDomiciliado = async () => {
        setLoadingPrintJustificantePagoCuenta(true);
        const result = await gateway.printJustificantePagosCuentaDomiciliacion(recibo.domiciliacion.idDomiRec);
        download(result, alertsDispatch,
            translate('PasarelaPago', 'No es posible descargar el justificante de pago', terms),
            translate('Global', 'BloqueoPantalla', terms));
        setLoadingPrintJustificantePagoCuenta(false);
    }



    // ----------------------------- HANDLERS -----------------------------

    const handleClose = useCallback(() => onClose(), [onClose]);

    const handleIdentificar = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        history.push({ pathname: '/tramites/conductor', state: recibo.id });
    }

    const handleGestionar = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        history.push({ pathname: '/cartero-virtual/domiciliaciones/domiciliados/domiciliados' });
    }

    const getLineasAuxiliares = useCallback(async (idRecibo: number) => {
        setLoading(true);
        const lineas = await gateway.getLineasAuxiliares(idRecibo)
        setLineasAux(lineas);
        setLoading(false);
    }, [gateway]);

    const handleIcon = (plazo: IDatosPlazos) => {
        switch (plazo.ESTADO) {
            case 'P':
                return (<Icon path={mdiCheck}
                    title="Pagado / Pagat"
                    size={0.7}
                    color="green"
                    className={classes.iconPC}
                />)
            case 'D':
                return (<Icon path={mdiWindowClose}
                    title="Devuelto / Retornat"
                    size={0.7}
                    color="red"
                    className={classes.iconPC}
                />)
            case 'E':
                return (<Icon path={mdiEmailSendOutline}
                    title="Enviado / Enviat"
                    size={0.7}
                    color="#006993"
                    className={classes.iconPC}
                />)
        }
    }
    const handleImporte = () => {
        let importe;

        if (recibo.organismo.codigo === "040" && recibo.domiciliacion.pagoALaCartaPalma)
            importe = recibo.desglose.principal
        else if (screen === 'cartero-virtual-pagado' && recibo.domiciliacion.pagoCarta)
            importe = recibo.importeRecibo;
        else
            importe = recibo.desglose.pendiente > 0 ? recibo.desglose.pendiente : recibo.desglose.pagado

        return importe;
    }

    const handleTogglePay = useCallback(() => {
        if (recibo) {
            (selected ? onUnselect : onSelect)(recibo);
            handleClose();
        }

    }, [handleClose, onSelect, onUnselect, recibo, selected]);

    const handleTogglePagoCarta = useCallback(() => {
        setOpenDatosPC(!openDatosPC)
    }, [openDatosPC]);


    const handlePrint = useCallback(async () => {
        if (recibo) {
            setLoadingPrint(true);
            let result;
            if (isFromConsultasExternas) {
                result = await paymentGateway.getJustificantePagoTributoConsultasExternas(recibo.id, recibo.sujeto.nif);
            } else {
                result = await paymentGateway.getJustificantePagoTributo(recibo.id);
            }
            download(result, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar el justificante de pago', terms),
                translate('Global', 'BloqueoPantalla', terms));
            setLoadingPrint(false);
        }
    }, [recibo, paymentGateway, alertsDispatch, terms]);



    // ----------------------------- USE EFFECTS -----------------------------

    useEffect(() => {
        if (open) {
            getLineasAuxiliares(recibo.id);
            setExcedenteCobro(recibo.desglose.pendiente < 0);
        }
        if (open && esPagoACuenta) {
            getListaPagos(recibo.domiciliacion.idDomiRec);
        }
    }, [recibo, open, getLineasAuxiliares]);

    useEffect(() => {
        const getDatosPc = async () => {
            const response = await gateway.getDatosPendiente(recibo.id)
            setDatosPc(response)
        }
        if (open && recibo.domiciliacion && recibo.domiciliacion.pagoCarta && recibo.organismo.codigo !== '040') {
            getDatosPc()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            fullWidth={true}
        >
            {/* INFORMACIÓN TRIBUTO Y ETIQUETA */}

            <DialogTitle>
                <Term component="CarteroVirtual" text="Información tributo" />
                {
                    recibo.estadoEtiqueta &&
                    <Typography className={[classes.etiquetaText].join(' ')}>
                        <Term component="Global" text={recibo.estadoEtiqueta} />
                    </Typography>
                }
                {
                    recibo.estadoEtiqueta === 'Domiciliación devuelta' && recibo.fechaDevolucion &&
                    <Typography className={classes.detailText}>
                        <Term component="Global" text='Fecha devolución' />: {moment(new Date(recibo.fechaDevolucion)).format('DD/MM/YYYY')}
                    </Typography>
                }
            </DialogTitle>

            <DialogContent>
                {
                    loading || recibo === null
                        ?
                        <div className={classes.centerContent}>
                            <CircularProgress size={75} />
                        </div>
                        :
                        <div className={classes.main}>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', marginBottom: '10px' }}>
                                <div>
                                    <Typography className={[classes.text, classes.bold].join(' ')} style={{ marginBottom: '10px' }}>{(municipio ? municipio.label + ' | ' : '') + recibo.ejercicio}</Typography>
                                    <div>
                                        <Typography className={classes.text}><Term component="Global" text="Nif" />: <span className={classes.bold}>{recibo.sujeto.nif}</span></Typography>
                                        <Typography className={classes.text}><Term component="Global" text="Nombre" />: <span className={classes.bold}>{recibo.sujeto.nombre}</span></Typography>
                                    </div>
                                </div>
                                <div >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon path={mdiCalendarMonth}
                                            size={1.5}
                                            color={"black"}
                                            style={{ marginRight: 10 }}
                                        />
                                        <div>

                                            {
                                                recibo.fechaInicio && recibo.fechaFin && !recibo.rehabilitado && recibo.fechaFin > recibo.fechaInicio
                                                    ?
                                                    <Typography component="p" className={[classes.text].join(' ')}>
                                                        <Term component="CarteroVirtual" text="Fecha inicio" />:&nbsp;
                                                        <span className={classes.bold}>{dateFormat(recibo.fechaInicio)}</span>
                                                    </Typography>
                                                    :
                                                    null
                                            }
                                            {
                                                recibo.fechaInicio && recibo.fechaFin && !recibo.rehabilitado && recibo.fechaFin > recibo.fechaInicio
                                                    ? <Typography component="p" className={[classes.text].join(' ')}>
                                                        <Term component="CarteroVirtual" text="Fecha fin" />:&nbsp;
                                                        <span className={classes.bold}>{dateFormat(recibo.fechaFin)}</span>
                                                    </Typography>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '45px' }}>
                                        {
                                            recibo.fPago ? <Typography className={classes.text} style={{ color: '#006993' }}><Term component="Global" text="Fecha pago" />: <span className={classes.bold}>{moment(recibo.fPago).format('DD/MM/YYYY')}</span></Typography> : null
                                        }

                                        {/* DWA-15392 TODO: revisar cambios tras eliminar fechaPrevistaCargo de Fr_recibos_WebQuery */}
                                        {
                                            recibo.domiciliacion && recibo.domiciliacion.fechaPrevistaCargo && recibo.domiciliacion.activa && recibo.domiciliacion.vigenteAnyoEnCurso
                                                ?
                                                <Typography component="p" className={[classes.text].join(' ')} style={{ color: '#006993' }}>
                                                    <Term component="CarteroVirtual" text="Fecha de adeudo" />:&nbsp;
                                                    <span className={classes.bold}>{recibo.domiciliacion.fechaPrevistaCargo}</span>
                                                </Typography>
                                                : null
                                        }
                                        {
                                            recibo.domiciliacion && recibo.domiciliacion.vigenteAnyoEnCurso
                                                ? <div className={classes.section}>
                                                    <Typography className={[classes.text].join(' ')} style={{ color: '#006993' }}>
                                                        <Term component="CarteroVirtual" text="IBAN de la cuenta (para cuentas en España)" />:&nbsp;
                                                        <span className={classes.bold} >{recibo.domiciliacion.iban}</span>
                                                    </Typography>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>

                                <div>
                                    {
                                        recibo.direccionTributaria ?
                                            <Typography className={classes.text}>
                                                <Term component="CarteroVirtual" text={recibo.concepto.idTipo === 7 || recibo.concepto.codigo === "IG" ? "lugar_infraccion" : "Dirección tributaria"} />: <br /><span className={classes.bold}>{recibo.direccionTributaria}</span>
                                            </Typography>
                                            : null
                                    }
                                </div>

                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={classes.imagenContainer}>
                                            <ConceptoIcon concepto={recibo.concepto.idTipo} codigo={recibo.concepto.codigo} />
                                        </div>
                                        <div>
                                            <Typography className={classes.text}><Term component="CarteroVirtual" text="Objeto" />: </Typography><span className={classes.bold}>{recibo.objeto}</span>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '45px' }}>
                                        <Typography className={classes.text}><Term component="Global" text="Referencia" />: </Typography><span className={classes.bold}>{recibo.referencia}</span>
                                    </div>
                                </div>
                            </div>
                            <pre className={classes.pre} dangerouslySetInnerHTML={{ __html: lineasAux }} />
                            {esPagoACuenta &&
                                <div className={classes.pcBlueRim}>

                                    {/* TÍTULO INFORMACIÓN PAGO A CUENTA DOMICILIADO */}

                                    <div onClick={toggleExpand} className={classes.text} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
                                        <div style={{ display: 'flex', gap: 5 }}>
                                            <Typography className={[classes.bold, classes.pcBlueTitle].join(' ')}>
                                                <Term component='Global' text="Informacion" />
                                            </Typography>
                                            <Typography className={classes.pcBlueTitle}>
                                                <Term component='CarteroVirtual' text="pago_a_cuenta_domiciliado" />
                                            </Typography>
                                        </div>
                                        <Icon
                                            path={expand ? mdiChevronDown : mdiChevronRight}
                                            title="Pago a cuenta"
                                            size={1}
                                            color={"#006993"}
                                            className={[classes.iconPC, classes.pcBlueTitle].join(' ')}
                                        />
                                    </div>

                                    {/* DESPLEGABLE PAGO A CUENTA */}

                                    {expand &&
                                        <div style={{ marginTop: 10 }} className={classes.pac_detail}>
                                            <Typography style={{ fontSize: 15 }}>
                                                <Term component='CarteroVirtual' text="PagosRealizados" />
                                            </Typography>

                                            {/* PAGOS REALIZADOS*/}

                                            <div style={{ padding: 20, display: 'flex', flexDirection: 'column', gap: 5 }}>
                                                {pagosACuentaList.map(pagoACuenta => (
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 30, marginRight: 30 }}>
                                                        <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>{moment(pagoACuenta.fechaEnvio).format('DD/MM/YYYY')}</Typography>

                                                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                            <TextNumber
                                                                component="span"
                                                                className={classes.fontS}
                                                                value={pagoACuenta.importe}
                                                                options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }}
                                                            />
                                                            {
                                                                pagoACuenta.idEnv
                                                                    ? <Icon color={'green'} size={0.6} path={mdiCheck} />
                                                                    : <Icon color={'red'} size={0.6} path={mdiClose} />
                                                            }
                                                        </div>

                                                        <Typography style={{ fontSize: 13 }}>{recibo.domiciliacion.iban}</Typography>
                                                    </div>
                                                ))}
                                            </div>

                                            {/* CONTAINER IMPORTES PAGOS A CUENTA */}

                                            <div className={classes.pac_detail_result}>

                                                <div>

                                                    {/* PLAZOS ENVIADOS */}

                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                        <Typography style={{ fontSize: 15, width: '20%' }}>
                                                            <Term component='CarteroVirtual' text="pago_a_cuenta_plazos_enviados" />:
                                                        </Typography>
                                                        <TextNumber
                                                            component="span"
                                                            className={[classes.fontM, classes.bold].join(' ')}
                                                            value={importePagadoAPlazos}
                                                            options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }}
                                                        />
                                                    </div>

                                                    {/* IMPORTE RECIBO */}

                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                        <Typography style={{ fontSize: 15, width: '20%' }}>
                                                            <Term component='CarteroVirtual' text="pago_a_cuenta_importe_del_recibo" />:
                                                        </Typography>
                                                        <TextNumber
                                                            component="span"
                                                            className={[classes.fontM, classes.bold].join(' ')}
                                                            value={handleImporte()}
                                                            options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }}
                                                        />
                                                    </div>
                                                </div>

                                                {/* IMPORTE PENDIENTE */}

                                                <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 15 }}>
                                                    <Typography style={{ fontSize: 15, width: '20%' }}>
                                                        <Term component='CarteroVirtual' text="Importe pendiente" />:
                                                    </Typography>
                                                    <TextNumber
                                                        component="span"
                                                        className={[classes.fontM, classes.bold].join(' ')}
                                                        value={handleImporte() - importePagadoAPlazos}
                                                        options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {recibo.domiciliacion && (recibo.domiciliacion.pagoCarta || recibo.domiciliacion.pagoALaCartaPalma)
                                ?
                                <div className={classes.pcBlueRim}>
                                    <Typography className={[classes.text, classes.bold, classes.pcBlueRimTitle].join(' ')} onClick={handleTogglePagoCarta}>
                                        <Term component="Domiciliaciones" text="+ Información pago a la carta" />
                                        {openDatosPC
                                            ?
                                            <Icon path={mdiChevronDown}
                                                title="Pago a la carta"
                                                size={1}
                                                className={classes.iconPC}
                                            />
                                            :
                                            <Icon path={mdiChevronRight}
                                                title="Pago a la carta"
                                                size={1}
                                                className={classes.iconPC}
                                            />
                                        }
                                    </Typography>
                                    {openDatosPC
                                        ?
                                        <>
                                            {
                                                recibo.domiciliacion.pagoALaCartaPalma ?
                                                    <>
                                                        <div dangerouslySetInnerHTML={{ __html: bodyAvisoPagoCartaPalma }} />
                                                    </>
                                                    :
                                                    <>
                                                        <Typography className={classes.text}><Term component="Domiciliaciones" text="Pagos realizados" />:</Typography>
                                                        <div style={{ margin: '0px 0px 20px 10px' }}>
                                                            <>
                                                                {datosPc?.plazos.map((x: IDatosPlazos) => {
                                                                    return (
                                                                        <div className={classes.pcPagosRealizados} key={x.PLAZO}>
                                                                            <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">{x.PLAZO}</Typography>
                                                                            <Typography className={[classes.text].join(' ')} variant="caption">{x.IMPORTE + '€'}{handleIcon(x)}</Typography>
                                                                            <Typography className={[classes.text].join(' ')} variant="caption">{x.IBAN}</Typography>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        </div>

                                                        {
                                                            datosPc && datosPc.proximo > 0 ?
                                                                <div className={classes.pcContainer}>
                                                                    <Typography className={classes.text}><Term component="Domiciliaciones" text="Proximo pago" />:</Typography>
                                                                    <div className={classes.pcDatosLabel}>
                                                                        {
                                                                            <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">
                                                                                {moment().format("YYYY") + " "}
                                                                                <Term component="Global" text={moment().month(1).format("MMMM")} />
                                                                            </Typography>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : <></>
                                                        }
                                                        {
                                                            datosPc && datosPc.Bonif > 0 ?
                                                                <div className={classes.pcContainer}>
                                                                    <Typography className={classes.text}><Term component="Domiciliaciones" text="Bonificacion" />:</Typography>
                                                                    <div className={classes.pcDatosLabel}>
                                                                        {
                                                                            <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">
                                                                                {datosPc?.Bonif + '%'}
                                                                            </Typography>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : <></>
                                                        }

                                                        <div className={classes.pcContainer}>
                                                            <Typography className={classes.text}><Term component="Domiciliaciones" text="Plazos enviados" />:</Typography>
                                                            <div className={classes.pcDatosLabel}>
                                                                {
                                                                    <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">
                                                                        {datosPc?.total.ENVIOS + '€'}
                                                                    </Typography>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={classes.pcContainer}>
                                                            <Typography className={classes.text}><Term component="Domiciliaciones" text="Importe recibo" />:</Typography>
                                                            <div className={classes.pcDatosLabel}>
                                                                {
                                                                    <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">
                                                                        {datosPc && datosPc.total.IMPORTE + '€'}
                                                                    </Typography>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={classes.pcContainer}>
                                                            <Typography className={classes.text}><Term component="Domiciliaciones" text="Bonificacion recibo" />:</Typography>
                                                            <div className={classes.pcDatosLabel}>
                                                                {
                                                                    <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">
                                                                        {datosPc && datosPc.total.BONIF + '€'}
                                                                    </Typography>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={classes.pcContainer}>
                                                            <Typography className={classes.text}><Term component="Representaciones" text="Pendiente" />:</Typography>
                                                            <div className={classes.pcDatosLabel}>
                                                                {
                                                                    <Typography className={[classes.text, classes.bold].join(' ')} variant="caption">
                                                                        {datosPc && datosPc.total.PEND + '€'}
                                                                    </Typography>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                        : <></>
                                    }
                                </div>
                                : <></>
                            }
                            {
                                excedenteCobro ?
                                    <Typography className={classes.blueContainer}>
                                        <Term isHtml component="CarteroVirtual" text="ExcedenteCobro" />
                                    </Typography>
                                    : null
                            }
                            <div className={classes.section}>
                                <Typography className={[classes.text].join(' ')}>
                                    {
                                        pendiente || recibo.organismo.codigo === "040"
                                            ?
                                            <Term component="CarteroVirtual" text="Importe" />
                                            :
                                            <Term component="CarteroVirtual" text="Importe pagado" />
                                    }

                                </Typography>
                                <TextNumber
                                    className={[classes.text, classes.bold].join(' ')}
                                    value={handleImporte()}
                                    options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                            </div>
                        </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default" disabled={loadingPrint}>
                    <Term component="Global" text="Cerrar" />
                </Button>
                {
                    puedeIdentificar === true && <Button variant="contained" color="primary" size="small" className={classes.btnAddPay} onClick={handleIdentificar}>
                        <Term component="CarteroVirtual" text={"Identificar"} />
                    </Button>
                }
                {
                    recibo.estadoEtiqueta === 'Domiciliado' &&
                    <Button variant="contained" color="primary" size="small" className={classes.btnAddPay} onClick={handleGestionar}>
                        <Term component="CarteroVirtual" text={"gestionar_domiciliacion"} />
                    </Button>
                }
                {
                    selectable && !esPagoACuenta &&
                    <Button variant="outlined" color="primary" onClick={handleTogglePay}>
                        <Term component="CarteroVirtual" text={selected ? ('Quitar para pagar') : (pendiente && screen === 'cartero-virtual-pendiente' ? 'Pagar' : 'Imprimir')} />
                    </Button>
                }
                {loadingPrint && <CircularProgress size={24} className={classes.buttonProgress} />}
                {
                    !selectable && recibo.desglose.pendiente === 0 && !esPagoACuenta &&
                    <Button variant="outlined" color="primary" onClick={handlePrint} disabled={loadingPrint || excedenteCobro}>
                        <Term component="CarteroVirtual" text="Imprimir justificante de pago" />
                    </Button>
                }
                {
                    esPagoACuenta &&
                    <div style={{ display: 'flex', gap: 10 }}>

                        <div style={{ position: 'relative' }}>
                            <Button variant="outlined" color="primary" onClick={handlePrintJustificantePagoCuentaReciboDomiciliado} disabled={loadingPrintJustificantePagoCuenta}>
                                <Term component="CarteroVirtual" text="imprimir_justificante_pago_a_cuenta" />
                            </Button>

                            {loadingPrintJustificantePagoCuenta && <CircularProgress size={24} className={classes.buttonProgress} style={{ left: '42%', bottom: '15%' }} />}
                        </div>

                        <Button variant="outlined" color="primary" onClick={handleModifPagoACuenta} disabled={loadingPrintJustificantePagoCuenta}>
                            <Term component="CarteroVirtual" text="modificar_pago_a_cuenta" />
                        </Button>
                    </div>
                }
            </DialogActions>
        </Dialog>
    )
};

export default ReciboDetail;