import React,{ FC, useCallback, useContext, useEffect, useState } from "react";
import { mdiLaptop } from "@mdi/js";
import Term from "components/term";
import usePage from "hooks/page.hook";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, Grid, MenuItem, makeStyles } from "@material-ui/core";
import { TMenuItem } from "containers/shared/menu-item-types";

import ConsultaDeuda from '../../resources/home/cartero/ConsultaDeuda.png';
import Domiciliaciones from '../../resources/home/cartero/Domiciliaciones.png';
import PagarDoc from '../../resources/home/cartero/PagarDoc.png';
import MasInfoIcon from '../../resources/home/notificaciones/info.png';

import BoxMenuItem from "components/box-menu-item";
import styles from "containers/shared/container-home";
import ModalAcceso from "components/modal-acceso";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";


interface IRouteParams {}
type Props = RouteComponentProps<IRouteParams>;

const useStyles = makeStyles(styles);

const CarteroVirtualHome: FC<Props> = ({match, history}) => {
    const classes= useStyles();
    const isMobile = window.innerWidth <= 590;
    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [showLogin, setShowLogin] = useState(false);

    const MENU_ITEMS: TMenuItem[] = [
        { title:{component: "CarteroVirtual", text:"ConsultaDeudas"}, img: ConsultaDeuda, children : [
            { title: { component:"CarteroVirtual" , text:"Deuda pagada" }, link: 'cartero-virtual/deuda-pagada', protected: 1, idModulo: 1},
            { title: { component: "CarteroVirtual",text:"Deuda pendiente"}, link: 'cartero-virtual/deuda-pendiente', protected: 1, idModulo: 2},
            { title: {component: "CarteroVirtual",text: "PagoTelematicoDocIngreso"}, link: '/cartero-virtual/pagoTelematico', protected: 0},
        ] },
        { title: {component:"CarteroVirtual",text:"DomiciliacionTributos"}, img: Domiciliaciones, children : [
            { title: {component: "Domiciliaciones",text: "tributos_domiciliados"}, link: 'cartero-virtual/domiciliaciones/domiciliados/domiciliados', protected: 1, idModulo: 3},
            { title: {component: "Domiciliaciones",text:"Tributos domiciliables"}, link: 'cartero-virtual/domiciliaciones/domiciliables', protected: 1, idModulo: 4},
            { title: {component:"Domiciliaciones",text:"Pago a la carta"}, link: 'cartero-virtual/domiciliaciones/domiciliados/pago-carta', protected: 1, idModulo: 5},
        ] },
        { title: {component: "Global", text:"Informacion"}, img: MasInfoIcon , children : [
            { title: {component: "CarteroVirtual", text:"formas_acceso_cartero_virtual"}, link: 'cartero-virtual/contenidos', protected: 0},
            { title: {component: "CarteroVirtual", text:"calendario_fiscal"}, link: `https://www.atib.es/TL/CalendarioFiscal.aspx?lang=`, isExternLink: true, protected: 0},
            { title: {component: "CarteroVirtual", text:"fechas_gestion_domiciliacion"}, link: pageState.lang === 'ca' ?`https://www.atib.es/TL/domiciliacion/PREVISIO%20DOMI%202025_c.pdf`:`https://www.atib.es/TL/domiciliacion/PREVISIO%20DOMI%202025_s.pdf`, isExternLink: true, protected: 0},
            { title: {component: "CarteroVirtual", text:"aplicacion_movil"}, link: `https://sede.atib.es/cva/app-redirect.html`, isExternLink: true, protected: 0},
            { title: {component: "CarteroVirtual", text:"preguntas_frecuentes"}, link: 'faqs/5', protected: 0},
        ] },

        
    ]

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLaptop,
                title: <Term component="Global" text="CarpetaVirtual" />,
                text:  <Term component="Global" text="CarpetaVirtual Descripcion" />,
                moreInfoTemplate: 'mas_info_cartero_virtual',
                right: (
                    <>
                        <MenuItem button onClick={() => handleShowMasInfo('mas_info_cartero_virtual')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
            footer: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher]);

    return(
        <Card className={classes.root}>
            <Grid container direction='row' justify="flex-start">
                {MENU_ITEMS.map( (menuitem, idx) => (
                    <Grid className={classes.widget} item xs={12} md={6} xl={4} key={`cartero-${idx}`}>
                        <BoxMenuItem 
                            item={menuitem} 
                            onLogin={(accessLevel) => {
                                if(isMobile){history.push('/acceso', { goBack: history.location.pathname }) } 
                                else {
                                    setShowLogin(true)
                                    if(accessLevel === 2){history.push('/acceso/clave', { goBack: history.location.pathname } )}
                                    if(accessLevel === 1){setShowLogin(true)}
                                } 
                            }} 
                        />
                    </Grid>
                    
                ))}
            </Grid>
            <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />
        </Card>
        
    )
}

export default withRouter(CarteroVirtualHome);

