import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";

import { withStyles, WithStyles, TextField, TextFieldProps, IconButton } from "@material-ui/core";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import styles, { colors } from "./styles";

import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { MoreInfoContext } from "contexts/more_info.context";
import Icon from "atomic/atoms/icon";

export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined,
    componentIG?:string
}

type TMSGError = 'outOfRange_max'|'outOfRange_min' | 'err_required' |'notAllowDecimal'| 'manyDecimals'
type TInput = Pick<TextFieldProps, 'className' | 'variant' | 'size' | 'margin'| 'InputProps' |'error' >  & WithStyles

export interface IInputNumber extends TInput {
    componentIG: string
    labelTerm: string,
    value: number| undefined,
    onChangeValue: (v: number|undefined, err: boolean) => void,
    max?: number;
    min?: number ;
    maxDecimal?: number;
    required: boolean,
    extraValidation?: (v: number|undefined) => TExtraValidation
    disabled?:boolean
    disableDropdown?: boolean;
    moreInfo?: {
        template: string, size?: TInfoTemplateSize
    },
    errorMsgExterno?:string
    isMoney?:boolean
    helperText?: string // Por defeceto tiene un espacio y se reserva el espacio que ocupa (helperText y msgError). Poner a '' si no se desea reservar el espacio.
};


const InputNumber : FC<IInputNumber>= ({
    classes, labelTerm, value, onChangeValue, // onChangeError,
    max, min, maxDecimal=2, required=false, disabled,
    variant='outlined', size='small', margin='dense', isMoney=false,helperText=' ',
    className, extraValidation, error ,errorMsgExterno, moreInfo,componentIG, ...props
}) => {
    const terms = useContext(LiteralsContext);
    const [, infoDispatch] = useContext(MoreInfoContext);

    const [localVal, setLocalVal] = useState<string >('')
    const [err, setErr] = useState<boolean>(false)
    const [errMsg, setErrMsg] = useState< string|undefined>(undefined)

    const [formatedValue, setformattedValue] = useState('')
    const [focussed, setFoccused] = useState(false)


    const validate = (value: number | undefined, withExtravalidation: boolean) => {
        let msg : TMSGError|undefined = undefined
        let msgComplement= ''
        let errDecimal=false
        let errManyDecimal=false
        let maxErr=false;
        let minErr =false;
        let prevError = false

        const requiredErr = required && value === undefined ? true : false
        if(requiredErr){ 
            msg='err_required'
            prevError=true
        } 
        
        maxErr = !prevError && max!== undefined && value ? value > max : false;
        minErr =!prevError &&  min!== undefined && value  ? value < min : false;

        if(maxErr || minErr){ 
            msg = maxErr ? 'outOfRange_max': 'outOfRange_min';
            msgComplement = maxErr ? max?.toString()?? '' : min?.toString() ??''
            prevError=true
        }

        if(!prevError){
            const valSTR = value ? value.toString().split('.') : []
            if(maxDecimal === 0 && valSTR[1]){
                errDecimal=true
                msg = 'notAllowDecimal'
            } else if(valSTR[1] && valSTR[1].length > maxDecimal){
                errManyDecimal = true;
                msg = 'manyDecimals'
                msgComplement = maxDecimal.toString()
            }
        }

        let result: TExtraValidation= {error: false,error_msg: undefined,}
        if(!prevError && extraValidation && withExtravalidation){
            result = extraValidation(value)
            if(result.error){ 
                onChangeValue(value, result.error)
            }
        }

        setErrMsg(  msg 
            ? translate('GenericComponents', msg,terms) +' '+ msgComplement 
            : translate(result.componentIG ?? 'GenericComponents', result.error_msg ?? 'Oops !!',terms)  
        )
        setErr( maxErr || minErr || requiredErr ||errManyDecimal || errDecimal || result.error)
        return maxErr || minErr || requiredErr ||errManyDecimal || errDecimal || result.error
    }

    useEffect(() => {
        if(value !== undefined){
            const strValue= value.toString().replaceAll(",", ".")
            setLocalVal(strValue)
            setformattedValue(value.toLocaleString('es-ES', { maximumFractionDigits:maxDecimal, minimumFractionDigits: maxDecimal ?? 0}))
        } else {
            setLocalVal('')
            setformattedValue('')
        }
    }, [value, focussed])



    useEffect(() => {
        // setea el error que me envían desde fuera
        if(error !== undefined){ 
            setErr(error)
            if(errorMsgExterno && error === true){ setErrMsg(errorMsgExterno)}
            if(error ===false){ setErr(false) }
        }
        if(value){ // Validar valor que envían desde fuera con validacion extra si no está el campo con foco
            validate(value, !focussed)
        } 
    }, [error, errorMsgExterno, value])

    const handleShowMoreInfo = useCallback(() => {
        if (moreInfo) {
            infoDispatch({
                type: 'show-info',
                payload: {
                    templateName: moreInfo.template ?? '',
                    size:moreInfo?.size ?? undefined,
                }
            });
        }
    }, [infoDispatch])
    
    return (
        <div style={{display:'flex', flexDirection: 'row', alignItems:'flex-start', marginRight: 10}} >
            <TextField 
                {...props}
                disabled={disabled}
                className={className ?? classes.inputDefault}
                size={size}
                variant={variant}
                type={'numeric'}
                label={translate(componentIG,labelTerm, terms).concat(required? '*':'')} 
                value={ ( focussed || !isMoney) ? localVal.replaceAll(".", ",") ?? '' : formatedValue} 
                onChange={(e) => {
                    e.preventDefault();
                    if(e.target.value !== ''){
                        const decimal= e.target.value.replaceAll(",", ".")
                        // if new value is valid float => allow MAX 5 decimales
                        if (/^(\-?[\d]*[.,]?[\d]{0,5})$/.test(decimal)) {
                            const parsed = parseFloat(decimal);
                            setLocalVal(decimal);
                            if(!isNaN(parsed)) {
                                // Al ser string - No cumple cuendo el ususairo inserta 15. por lo que no nos inetresa guardar el valor
                                // queda registrado en anterior 15 y se registrará el siguiente en cumplir EJ: 15.1
                                // si el usuario deja un valor 15. => solo se guarda 15
                                const err = validate(parsed,false)
                                onChangeValue(parsed,err)
                            }
                        } 
                    } else {
                        
                        const err = validate(undefined, false)
                        onChangeValue(undefined,err)
                        setLocalVal(e.target.value);
                    }
                }} 
                error={err}
                helperText={ err ? (errMsg ?? ' ') : helperText}
                margin={margin}
                onFocus={() => {
                    setformattedValue(''); 
                    setFoccused(true)
                }}
                onBlur={() => {
                    const err = validate(value, true)
                    err && onChangeValue(value,err)
                    setFoccused(false)
                }}
                InputLabelProps={{classes: {
                    root: classes.inputLabel,
                }}}
            />
            {moreInfo && 
                <IconButton onClick={handleShowMoreInfo} style={{marginTop: 8}}>
                    <Icon name={'info-outline'} size={1} color={colors.blueIconInfo}/>
                </IconButton>
            }
            
        </div>
           
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(InputNumber));