import { TDirectionATIB } from "components/generic-sujeto/DirectionATIB"

export type TTipo = 'solicitud'|'reintegro'
export type TTipoSolicitud= 'anticipado' | 'insuficiencia' 
export type TModalidadSolicitud=  'individual' | 'conjunta'
export type TParentesco = 'madre'|'padre'
export type TIDentifica = { nif: string|undefined, nombre: string|undefined, apellidos: string|undefined, telefono:  string|undefined, email:  string|undefined, parentesco: TParentesco|undefined }
export type TProgenitor = TIDentifica & {solicita: boolean | undefined, noResidente: boolean|undefined}
export type TDeduccion ={
    nombre: string|undefined, 
    apellidos: string|undefined
    fechaNacimiento: Date | undefined,
    //lugarNacimiento: string | undefined,
    paisId: string | undefined,
    paisNombre: string | undefined,
    provinciaId: string | undefined,
    provinciaNombre: string | undefined,
    municipioId: string | undefined,
    municipioNombre: string | undefined,
}
export const EMPTY_Deduccion : TDeduccion = {
    nombre: undefined,
    apellidos: undefined,
    fechaNacimiento: undefined,
    //lugarNacimiento: undefined,
    paisId: '00',
    paisNombre: 'ESPAÑA',
    provinciaId: '07',
    provinciaNombre: 'ILLES BALEARS',
    municipioId: undefined,
    municipioNombre: undefined
}

export type TDatosIdentificativos = {
    tipoSolicitud: TTipoSolicitud | undefined,
    modalidadSolicitud: TModalidadSolicitud | undefined,
    familiaMonoparental: boolean |undefined
    contribuyente: TIDentifica | undefined, // Datos del panel principal o contribuyente
    progenitor: TProgenitor | undefined, // Datos del panel progenitor (No aplica en el IRDP-DA-51)
    derechoDeduccion: TDeduccion | undefined // Datos del panel nacido con derecho deducción
}

export type TRepresentante =  Omit<TIDentifica, 'parentesco'|'nombre'|'apellidos'> &{nomApe: string | undefined}
export type TDireccionRepresentante = {
    dirNotificacion: TDirectionATIB
    representante: TRepresentante | undefined
}

export type TTipoDocGenera = 50 | 51 | 52 | 53
export type DeclaracionMap = {
    [K in TTipoDocGenera]: K extends 50 ? TDeclaracionResponsable50 :
                   K extends 51 ? TDeclaracionResponsable51 :
                   K extends 52 ? TDeclaracionResponsable52 :
                   TDeclaracionResponsable53;
};
//export type TTipoDocGeneraReintegro = 50 | 51 | 52 | 53
export type TBIValor= {
    baseImponible_valor1: string | undefined
    baseImponible_valor2: number| undefined
}
export type TDeclaracionResponsable50 = {
    numHijosImpuestoIRPF: boolean
    numHijosImpuestoIRPF_valor: number | undefined
    familiaNumerosa: boolean | undefined,
    familiaNumerosa_valor: boolean | undefined
    residenteIB: boolean
    baseImponible: boolean
    baseImponible_valor: TBIValor 
    noOtrasAyudas: boolean
    /*--  No aplica TRAS REUNIÓN 09/07/2024 - Si no es monoparental siempre asignamos 50% de importe al solicitante tengan o no ambos derecho
    // numProgenitores:boolean,
    // numProgenitores_valor: number| undefined
    -----------------------------------------------------------*/
    compromisoReintegro: boolean
    //autorizaConsultaDatos: boolean
    ingresoIBAN: boolean
    ingresoIBAN_valor: string| undefined
}

export type TCausa = 'excede_limite' | 'fecha_meritacion' | 'cambio_residencia' | 'no_presentacion_IRPF' | 'otras_ayudas'| 'otros' | 'otros_descripcion' 
export type TCausaValor = {[K in TCausa]: K extends 'otros_descripcion' ? string|undefined: boolean};
export type TDeclaracionResponsable51 = {
    recibeAbono: boolean,
    esCausa: boolean,
    esCausa_valor: TCausaValor,
    perdidaDerecho: boolean,
    noRegularizado:boolean
}


export type TNHProgenitor = {
    numHijosProgenitor_valor1: number | undefined,
    numHijosProgenitor_valor2: number | undefined
}
export type TDeclaracionResponsable52 = {
    numHijosProgenitor:boolean,
    numHijosProgenitor_valor: TNHProgenitor,
    familiaNumerosa: boolean | undefined,
    familiaNumerosa_valor: boolean | undefined
    residenteIB: boolean
    baseImponible: boolean
    baseImponible_valor: TBIValor 
    noOtrasAyudas: boolean
    importeIRPF: boolean
    importeIRPF_valor: number | undefined
    compromisoReintegro: boolean
    ingresoIBAN: boolean
    ingresoIBAN_valor: string| undefined
}
export type TCausa2 = 'perdida_derecho' | 'regularizacion' |'otros' | 'otros_descripcion' 
export type TCausaValor2 = {[K in TCausa2]: K extends 'otros_descripcion' ? string|undefined: boolean};
export type TDeclaracionResponsable53 = {
    recibeAbono: boolean,
    esCausa: boolean,
    esCausa_valor: TCausaValor2,
    perdidaDerecho: boolean,
    //noRegularizado:boolean
}

export type TDeclaracionResponsable = {
    tipoDocGenera: TTipoDocGenera | null
    declResponsable: TDeclaracionResponsable50 | TDeclaracionResponsable51 | TDeclaracionResponsable52 | TDeclaracionResponsable53 | null;
    codigoPostal_valor1: string | null;
    codigoPostal_valor2: string | null;
    modalidadSolicitud: TModalidadSolicitud | null;
    residenteIB_validacion: boolean | 'error' | null;
    excedeBaseImponible: boolean;
}

export type TAutorizacion = {
    //noNotificacionesATIB: boolean
    presentaDeclIRPFAnterior: boolean | undefined
    declIRPFAnterior: boolean |string 
    certifImputacionFiscal: boolean | string  
    certifResidenciaFiscal: boolean | string  
    certifNacimiento: boolean | string 
    autorizaConsultaDatos: boolean;
    tipoNotificacion: 'electronica'| undefined
    certifRepresentacionTerceros: undefined |string; // Solo lo usa IntriGest
}

export type TFinSol = {
    fechaFin: Date
    importeSolicitud: number | undefined
}
export type TFinReint = {
    importeAIngresar: number | undefined,
    fechaFin: Date
}



export type TIdentificacionAbono = {
    fechaSolicitud: Date | undefined,
    importePercibido: number | undefined,
    ejercicio: number | undefined,
    numSolicitud: number | undefined,
    idSolicitud: number| null
}

export type TSolicitudAbonoNacimiento = {
    datos_identificativos: TDatosIdentificativos | undefined;
    direccion_representante: TDireccionRepresentante | undefined;
    declaracion_responsable: TDeclaracionResponsable | undefined;
    autorizacion: TAutorizacion;
    finalizacion_justificante: TFinSol | undefined;
}
export type TReintegroAbonoNacimiento = {
    datos_identificativos: TDatosIdentificativos | undefined;
    direccion_representante: TDireccionRepresentante | undefined;
    identificacion_abono: TIdentificacionAbono | undefined
    declaracion_responsable: TDeclaracionResponsable | undefined;
    autorizacion: TAutorizacion;
    finalizacion_justificante: TFinReint| undefined;
}


export type TTipoDocAdjunto = 'declIRPFAnterior'|'certifImputacionFiscal'|'certifResidenciaFiscal'|'certifNacimiento'
export type TTipoEstado = 
    'SPF' | //Solicitud pendiente de firma
    'SPT' | //Solicitud presentada en tramitació
    'SAP' | //Solicitud aprobada pendiente abono
    'SAA' | //Solicitud aprobada y abonada
    'SDE' | //Solicitud denegada
    'RPP' | //Autoliquidacion reintegro pendiente ingreso
    'RPF' | //Autoliquidacion reintegro pendiente firma
    'RPI' | //Autoliquidacion reintegro presentada e ingresada
    'ERR'   //Solicitud/Reintegro error

export type BIOPT = {
    id:number, 
    term:string,
    limiteBI: number, 
    modalidad: 1 | 2 | null,
    fNumerosa: boolean | null,
    fMonoparental: boolean | null
}
export type TInfoDoc = FileList /* {
    key: TTipoDocAdjunto,
    doc: FileList | null;
}*/


